@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Flex:opsz,wght@8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
* {
  font-family: "Inter", sans-serif;
  scrollbar-gutter: stable;
  scroll-behavior: smooth;
}
/* .childrenContainer::-webkit-scrollbar {
  width: 0px !important;
} */

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding: 0 !important;
}

::-webkit-scrollbar {
  width: 3px;
}
.sideBarScroll::-webkit-scrollbar {
  /* width: 0px !important; */

  display: none;
}
.containerScroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.containerScroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  width: 1px;
  display: none;
}
/* Handle */
.containerScroll::-webkit-scrollbar-thumb {
  background: #4285f4;
  border-radius: 10px;
}

button {
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
button:active,
a:active {
  -webkit-transform: scale(0.98);
  -ms-transform: scale(0.98);
  transform: scale(0.98);
}
.inter {
  font-family: "Inter", sans-serif;
}
.roboto {
  font-family: "Roboto Flex", sans-serif;
}
.test {
  border: 1px solid red;
}
.navShadow {
  background: #fff;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.sidebarShadow {
  background: #fff;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.clientDashboardShadow {
  background: #fff;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.sidebarPadding {
  padding: 8px 0px 8px 22px;
}
.sidebarHeight {
  height: calc(100vh - 64px);
}
.childHeight {
  width: calc(100% - 215px);
}
.childHeight2 {
  width: calc(100% - 80px);
}
.childRealHeight {
  height: calc(100vh-64px);
}
.active {
  background-color: #4285f4;
  color: #fff;
  font-style: normal;
}
.activeIcon {
  color: #fff;
}
.filterBorder {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.filterBorder2 {
  border-radius: 4px;
  border: 1px solid #1765dc;
}
.tableBorder {
  border-radius: 4px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}
.cardShadow {
  border-radius: 12px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}
/* accordian box shadow remove */
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
/* accordian padding */
.css-15v22id-MuiAccordionDetails-root {
  padding: 4px 6px !important;
}
/* expand accordian */
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 13px 0;
}
.accContainer > * {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.css-67l5gl {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  position: static !important;
}
.css-pwxzbm {
  border-radius: 12px !important;
}
.css-r8u8y9 {
  padding: none !important;
}
.clientBoxShadow {
  border-radius: 4px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.mainInvoiceContainer h1,
.mainInvoiceContainer p {
  font-family: "Roboto Flex", sans-serif;
}
@media print {
  .printInvoice p {
    font-size: 11px;
  }
  .pageOne {
    position: relative;
    height: 100vh;
  }

  .pageTwo {
    position: relative;
    min-height: 95vh;
  }
  .extraDiv {
    display: none;
    height: 0px;
  }
  .myFooter1 {
    width: 100%;
    position: absolute;
    bottom: 50px;

    padding-top: 14px;
  }
  .myFooter2 {
    width: 100%;
    position: absolute;
    bottom: 0px;

    padding-top: 14px;
  }
  .pageTwoMargin {
    margin-top: 0px;
  }
  .fullInvoiceContainer {
    padding: 2rem 2rem 0 2rem;
  }
}
.glassmorph {
  background: rgba(255, 255, 255, 0.15);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border-radius: 10px;
  border: 1px solid #1a2035;
}
button:hover {
  scale: 0.97;
}
.css-67l5gl {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.css-1km1ehz {
  padding: 6px 0px;
}

.navHover::before {
  content: "";
  height: 100%;
  width: 0%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e3edfd;
  /* transform: scaleX(0); */
  z-index: 0;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.navHover:hover::before {
  width: 100%;

  /* transform: scaleX(1); */
}
.navHover2::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e3edfd;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  z-index: 0;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.navHover2:hover::before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
/* .uploadInvoiceSupplier * {
  font-family: "Arimo", sans-serif;
  font-weight: 400;
} */
.uploadSectionShadow {
  -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.uploadInputShadow {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}
/* input:invalid,
select:invalid {
  border: 1px solid red;
} */
